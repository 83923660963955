import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-9420_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9420 2k+ WQHD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9420_WQHD/Quick_Installation/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9420 2k+ WQHD Quick Installation",
  "image": "./P_SearchThumb_IN-9020HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9020HD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9020HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-9420 2k+ WQHD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-9420 2k+ WQHD is the first INSTAR camera with the new 1440p chipset.' image='/images/Search/P_SearchThumb_IN-9020HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9020HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9420_WQHD/Schnell_Installation/' locationFR='/fr/Outdoor_Cameras/IN-9420_WQHD/Quick_Installation/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-9420-2k-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9420-2k-wqhd-manual",
        "aria-label": "in 9420 2k wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9420 2k+ WQHD Manual`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#wall-installation"
        }}>{`Wall Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ceiling-installation"
        }}>{`Ceiling Installation`}</a></li>
    </ul>
    <h2 {...{
      "id": "wall-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wall-installation",
        "aria-label": "wall installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wall Installation`}</h2>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2eb1c074d8a6871b6a0deff665e295c0/fcda8/9420-Step-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsElEQVQoz3VS3+6bIBj1/W/3CHuPLVmym9+WdImp2tZMRWMREEGlyifC0pI413bnggD5/p1zvsC9wFq7nfsfD2PMMAzTNDnnAvcfrOuqtTbG+KfWuuu6PM/P53MYhgih98nzPGOMT6eTMUYpVdd1mqZhGB6Px6ZpGGMYY4QQAASvmZTSuq6rqhJCnB7AGPs5nXNt2+Z5nqbpsizBE7FhGDjnWuuqqgghRVHsVfAXzrkQYhiG585aa0KIcw4hdL1eMcaev3NOKSWl9GEA0HXdG85SSsbY5XIhhFRVpbWepgkA5nlmjBljAKBt23+Srd3mcqu167oKIaIowhhTSoUQ4zhSSp1z4zgmScIYC/5mPArcnTx8h/jXCMuPj4/D4YAQ6vveO2ytXZaFEJIkCSEk2LGdB6Xib19+f/40/vwqJy1Fl2UZ5xwANtm01nVdF0WhlAqWZeGcI4TSNI2TBOW5vBzdet+N2+0WRdE8z3v/y7IUQngJg7Zt4zguy5JSusVtTbIsa5oGALzzUsqNo7U2AIC+77c1vAvwqOrRdV0cxxjjcRz3uvr7s9qvznmd3gb8AQ24Iwu7uGHCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2eb1c074d8a6871b6a0deff665e295c0/e4706/9420-Step-1.avif 230w", "/en/static/2eb1c074d8a6871b6a0deff665e295c0/d1af7/9420-Step-1.avif 460w", "/en/static/2eb1c074d8a6871b6a0deff665e295c0/9984b/9420-Step-1.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2eb1c074d8a6871b6a0deff665e295c0/a0b58/9420-Step-1.webp 230w", "/en/static/2eb1c074d8a6871b6a0deff665e295c0/bc10c/9420-Step-1.webp 460w", "/en/static/2eb1c074d8a6871b6a0deff665e295c0/5ca24/9420-Step-1.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2eb1c074d8a6871b6a0deff665e295c0/81c8e/9420-Step-1.png 230w", "/en/static/2eb1c074d8a6871b6a0deff665e295c0/08a84/9420-Step-1.png 460w", "/en/static/2eb1c074d8a6871b6a0deff665e295c0/fcda8/9420-Step-1.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2eb1c074d8a6871b6a0deff665e295c0/fcda8/9420-Step-1.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Please loosen the bottom screw of the wall mount bracket as shown above in Fig.1.`}</p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b5e3496d56672d5303cea2c469984c0/fcda8/9420-Step-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmUlEQVQoz31Ry47bMAzM3+9H9FN62lxa9NQEvQQJIruOLcuWbbGy3pa4WGuRtEm6c5JIDjic2eB/kFJyzi0rvPfTNFFKy7LcbrdCiDywecp0zl0ul9PpJIT4vaJpGqVUjJEQ0nXdZ+RhGBhjVVVRSud5zsUQgve+bVvGWK5sHtUi4jiOAEAIoZTGGJ1z8zyHEJRSjLG6rmOM/5DTiuuScRzP53Pf9zFGpVRuWWunaaqqKoRwvznGaK0VQjDGuq6LMe52O++9c06tQMSu6/b7/bIsN3L2k3MuhJBSUkoPh4PWuigKKeVVl1KKEFIUxe3mEELf98aYfAki5ttCCMMKRBRCFEXBGDPGXJVucjAAkP9a6/xelsVaq7Uuy7Jpmr7vjNF37n7I5pwzxoQQANC2LSLWdT1NEyLOf8BZ+3cWN3L+U0oJIQBgrXXOGWOOx2MWnAeiHBct7zfn3jAMAJA9TCkBAOd8SZhm8D+/+R9fA/mVgnsuW0qZw9RaK6XeY1zN899f9ZeXJEUW/ZxsreWcG2Ny+o/nvTMfyG9PHijht8eZlQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b5e3496d56672d5303cea2c469984c0/e4706/9420-Step-2.avif 230w", "/en/static/9b5e3496d56672d5303cea2c469984c0/d1af7/9420-Step-2.avif 460w", "/en/static/9b5e3496d56672d5303cea2c469984c0/9984b/9420-Step-2.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b5e3496d56672d5303cea2c469984c0/a0b58/9420-Step-2.webp 230w", "/en/static/9b5e3496d56672d5303cea2c469984c0/bc10c/9420-Step-2.webp 460w", "/en/static/9b5e3496d56672d5303cea2c469984c0/5ca24/9420-Step-2.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b5e3496d56672d5303cea2c469984c0/81c8e/9420-Step-2.png 230w", "/en/static/9b5e3496d56672d5303cea2c469984c0/08a84/9420-Step-2.png 460w", "/en/static/9b5e3496d56672d5303cea2c469984c0/fcda8/9420-Step-2.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b5e3496d56672d5303cea2c469984c0/fcda8/9420-Step-2.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Slide the metal plate towards the bottom of the wall mount bracket and take out the metal plate.`}</p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22ae20e38a18b30ed4ce962dc024cbec/fcda8/9420-Step-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVQoz32TTW7rMAyEe/9V7/Au0U2v8FYpAhSoEyB1nMauZUv8EWmLKmIBQVvYnYVW+sjRkHrIG5rnWVVFZJomZgYAImJm7z0RlTsPvxgRIaIYYwHmeWZmRCyAqgJAjDGltAKHEOIiEem6rm1bZj4cDnVd3ytuwoioqiGElFJd18fjUVWrqrpcLjlnM2PmGKOZrcAAICLee1U9n88hhJzzOI5N05TOxdpm5xKVH0de5JzLOTdNg4jTNCHiJszMOedkFhBFBBELfL1eRaRcWLGdUrqF1LYeAPrOnQ6D933f3zsPw0BEP2yXGs65atFut3t9e9s9P13/PbqX/5fe1e/vZtb3fXlRGeQP2Ht/Op2cc7fO3rumhuoVP5pP5/b7PQAMw1Dc/pU2Ed3SCmEyw2WfxnHsug4WIeJm2mZGROX8vqclqu9zXk+biADAey8iMUZVve+mmaWUypKv274nr6rTIlWlRcxMRM45VS0f4wtDHCwuZQZSCgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22ae20e38a18b30ed4ce962dc024cbec/e4706/9420-Step-3.avif 230w", "/en/static/22ae20e38a18b30ed4ce962dc024cbec/d1af7/9420-Step-3.avif 460w", "/en/static/22ae20e38a18b30ed4ce962dc024cbec/9984b/9420-Step-3.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22ae20e38a18b30ed4ce962dc024cbec/a0b58/9420-Step-3.webp 230w", "/en/static/22ae20e38a18b30ed4ce962dc024cbec/bc10c/9420-Step-3.webp 460w", "/en/static/22ae20e38a18b30ed4ce962dc024cbec/5ca24/9420-Step-3.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22ae20e38a18b30ed4ce962dc024cbec/81c8e/9420-Step-3.png 230w", "/en/static/22ae20e38a18b30ed4ce962dc024cbec/08a84/9420-Step-3.png 460w", "/en/static/22ae20e38a18b30ed4ce962dc024cbec/fcda8/9420-Step-3.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22ae20e38a18b30ed4ce962dc024cbec/fcda8/9420-Step-3.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Use the included screws and dowels and mount the metal plate on the wall.`}</p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/18c4c06e886d086e5e6fe96fb53cc816/fcda8/9420-Step-4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOUlEQVQoz52S626DMAyFef+nbFGpaErIBcdJjONO4Amt3aStO78SS58vx+4e76iU4r1f11W/3d9JZh7H0Tn3Hzil1J/PtVYiehsWEWut9/4JrrvKrlpra01EvjL6IKJxGDDGlNInzMwpJWYWEWYmItxVShGRnPOyLI/HI+d8v9+n2y3OszEGADYYdrXWxnE8nBCRWmvOOcaYcy6lTNPEzDdj4rIQkXOOmTfYWhtjHIbB79KamgURAcAYw8yIeLlcAICI0q5t5nVdrbUiotPWWgFAu0DEEEIpRWe+Xq8xxtZaSgkAOl2g9/7FWOX1KlprGlQLNI6IG9xaU1de+JTSsiyIyMwajDGGEPQrIp32czqdftwtABzwUflpz0TU9/33yjrzPM+HfyEEa+3RyC8XVmt1zh251l1Hrg+d0jUY+ndJ3QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/18c4c06e886d086e5e6fe96fb53cc816/e4706/9420-Step-4.avif 230w", "/en/static/18c4c06e886d086e5e6fe96fb53cc816/d1af7/9420-Step-4.avif 460w", "/en/static/18c4c06e886d086e5e6fe96fb53cc816/9984b/9420-Step-4.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/18c4c06e886d086e5e6fe96fb53cc816/a0b58/9420-Step-4.webp 230w", "/en/static/18c4c06e886d086e5e6fe96fb53cc816/bc10c/9420-Step-4.webp 460w", "/en/static/18c4c06e886d086e5e6fe96fb53cc816/5ca24/9420-Step-4.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/18c4c06e886d086e5e6fe96fb53cc816/81c8e/9420-Step-4.png 230w", "/en/static/18c4c06e886d086e5e6fe96fb53cc816/08a84/9420-Step-4.png 460w", "/en/static/18c4c06e886d086e5e6fe96fb53cc816/fcda8/9420-Step-4.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/18c4c06e886d086e5e6fe96fb53cc816/fcda8/9420-Step-4.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Put the extension cable through the wall, and then cross through the wall-mount bracket as shown in the Fig.4.`}</p>
    <h3 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/fcda8/9420-Step-5-DE.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBUlEQVQoz82STU/DMAyG9/9/1w4IJESZENIOHIKG1jQOdexkceqkqNtlYiC+LjzKKcrjV469mv/A6luval1Oa7+Sf5B8kTBtuunxQcFpzl8na63EPCLiOGbV6fqq3N3qMNR3MhH1fe+cs9aWlOZSNKXRWiHadB0AxBhlmk4VHYAbBmvtdLxZee+NMS+73X6/z4hzjAmA+14Dme02hBBjfAVoqk21iAiziNRaFxkAno4Q81xKSyl5n7wvGG7Wa2PMfdc9G8PMiEhEFBZEZJGZeTgSY5xV51KEGQfXRDKRqqaUQgjtgk8/jGNExJQzESGiqn48qvNK54jI4XDIOZ/a+8OG/Tv5DQ05NSwX410tAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4620ccdf3d0bd81ed2014cdc334922e/e4706/9420-Step-5-DE.avif 230w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/d1af7/9420-Step-5-DE.avif 460w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/9984b/9420-Step-5-DE.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d4620ccdf3d0bd81ed2014cdc334922e/a0b58/9420-Step-5-DE.webp 230w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/bc10c/9420-Step-5-DE.webp 460w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/5ca24/9420-Step-5-DE.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4620ccdf3d0bd81ed2014cdc334922e/81c8e/9420-Step-5-DE.png 230w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/08a84/9420-Step-5-DE.png 460w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/fcda8/9420-Step-5-DE.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/fcda8/9420-Step-5-DE.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Attach the wall-mount to the metal plate on the wall. Once attached, please tighten the screw at the bottom of the wall-mount.`}</p>
    <h3 {...{
      "id": "step-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-6",
        "aria-label": "step 6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 6:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c48eee3b392b4128c9bd98c8786427e/fcda8/9420-Step-6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVQoz82Sy07DMBBF+///BRKCRdTAoqv0oQYTx44f4/FzYpSwKQIEajcceWXNuTMae1NvYPOnKqLlzPNV8pWd125526SXtoycQvhdLqVYa5VSWqmQc3p6SNumjLx4/0m21jLGOOdvwxABaggZYGIsGtM2jRDCIcacl0SiUQjBF/J6s5mm6XA49H3/ylgwpobgpQTOCWC/22mltNZiGFKMKUaP6LVG70spiyyl7Lpu33UWoOZcvfdKoZTJ2se7+9Px+Ny2/fnsvQcARES3kFJaZAAYV5xztZSaUgTQfKQQEgARIaIxZv7CjwtzzimtHaK1Vmv9MeQ3T3WZdEmKMawQ0W0/7N/J7/MTNTOS9hcNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c48eee3b392b4128c9bd98c8786427e/e4706/9420-Step-6.avif 230w", "/en/static/1c48eee3b392b4128c9bd98c8786427e/d1af7/9420-Step-6.avif 460w", "/en/static/1c48eee3b392b4128c9bd98c8786427e/9984b/9420-Step-6.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c48eee3b392b4128c9bd98c8786427e/a0b58/9420-Step-6.webp 230w", "/en/static/1c48eee3b392b4128c9bd98c8786427e/bc10c/9420-Step-6.webp 460w", "/en/static/1c48eee3b392b4128c9bd98c8786427e/5ca24/9420-Step-6.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c48eee3b392b4128c9bd98c8786427e/81c8e/9420-Step-6.png 230w", "/en/static/1c48eee3b392b4128c9bd98c8786427e/08a84/9420-Step-6.png 460w", "/en/static/1c48eee3b392b4128c9bd98c8786427e/fcda8/9420-Step-6.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c48eee3b392b4128c9bd98c8786427e/fcda8/9420-Step-6.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Connect the connection cable with the camera according to the drawing shown in Fig.6.`}</p>
    <h3 {...{
      "id": "step-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-7",
        "aria-label": "step 7 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 7:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f64ad5200783f84cd916a09574f1437/fcda8/9420-Step-7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz5WT207DMAyG9/6vhbhF3G0S66DHLD0lNOc4qdFaactEYeCLSJH92b/tZIePbJ7n9RyGwXufunZJUJxDuIXfw8aYoihgDbiHL+6oVOD8Eqe11cpa65wDAK11jNEYU+W5FOIbvOQOVQmvLwGgfTvWh8PEmAfw3hNCOOd91zVl2bVtqvwGx2EIpyMiKs75MHjntDExxr7vKaVG67KqhJQiKZ70bE38/ERENo4NIWtS5xwhRGuNiH3fa6Wcc1uy30/w/CQ9fGRZkedmAeZ5buraGIOI9Eyttds9RzGFth2nSSkFAGKa/FLELOIv7UgZtqa9WIxWyus81gn//gR26cUvdt2ttXatmS78RxgAKKXjOAohijzvum6T2YYRsWvbuq4R8ZRljLH/wVLKsigYY4f9PtX8JziE0DTNmRCt1MM/8wUmDzO52NUjpwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f64ad5200783f84cd916a09574f1437/e4706/9420-Step-7.avif 230w", "/en/static/2f64ad5200783f84cd916a09574f1437/d1af7/9420-Step-7.avif 460w", "/en/static/2f64ad5200783f84cd916a09574f1437/9984b/9420-Step-7.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f64ad5200783f84cd916a09574f1437/a0b58/9420-Step-7.webp 230w", "/en/static/2f64ad5200783f84cd916a09574f1437/bc10c/9420-Step-7.webp 460w", "/en/static/2f64ad5200783f84cd916a09574f1437/5ca24/9420-Step-7.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f64ad5200783f84cd916a09574f1437/81c8e/9420-Step-7.png 230w", "/en/static/2f64ad5200783f84cd916a09574f1437/08a84/9420-Step-7.png 460w", "/en/static/2f64ad5200783f84cd916a09574f1437/fcda8/9420-Step-7.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f64ad5200783f84cd916a09574f1437/fcda8/9420-Step-7.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Attach the camera unit to the wall-mount. To do so, please loosen the 3 screws at the wall mount and then attach the camera and tighten the screws again.`}</p>
    <h2 {...{
      "id": "ceiling-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ceiling-installation",
        "aria-label": "ceiling installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ceiling Installation`}</h2>
    <h3 {...{
      "id": "step-1-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1-1",
        "aria-label": "step 1 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ae078a9cc0856d9862b86f228404d31/fcda8/9420-Ceiling---Step-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVQoz5WSQW/CMAyF+f+/Z7ddd9yJK0KVKtEVicYmjZ3EcRI0MkHFNrb6lMjvk96zvalPS1WJyFrrnDufz9baZXfzhBQRRLTWxhhFJOf8ILjDZVHti4jMvFTfunc4pXQ6nZxzABBCYGYA8N7PxiAAEXnvAYCZ24OIQghfsKoiYovUeGutMSbMMzk3TVMIYZ5nAIgxMvM0Tc3RpplRVRFRVWbuum4cR0Tsug4Rj8fjbrfz3qeUWvJb/seBlVJyzsy83+9FJKXU9/34MSyj/jFtEYFrMRExuxB/lP26qlIKAJRaMzn7/lZj48sK+NMFTIfXl3Idby3/ho0xLcJhGFfbbseYRA59vw5W1e12m1KqtYbg18E552EYvt/zEr4ATNk1qupX11oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ae078a9cc0856d9862b86f228404d31/e4706/9420-Ceiling---Step-1.avif 230w", "/en/static/0ae078a9cc0856d9862b86f228404d31/d1af7/9420-Ceiling---Step-1.avif 460w", "/en/static/0ae078a9cc0856d9862b86f228404d31/9984b/9420-Ceiling---Step-1.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ae078a9cc0856d9862b86f228404d31/a0b58/9420-Ceiling---Step-1.webp 230w", "/en/static/0ae078a9cc0856d9862b86f228404d31/bc10c/9420-Ceiling---Step-1.webp 460w", "/en/static/0ae078a9cc0856d9862b86f228404d31/5ca24/9420-Ceiling---Step-1.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ae078a9cc0856d9862b86f228404d31/81c8e/9420-Ceiling---Step-1.png 230w", "/en/static/0ae078a9cc0856d9862b86f228404d31/08a84/9420-Ceiling---Step-1.png 460w", "/en/static/0ae078a9cc0856d9862b86f228404d31/fcda8/9420-Ceiling---Step-1.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ae078a9cc0856d9862b86f228404d31/fcda8/9420-Ceiling---Step-1.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Drill the holes according to the drilling template. Put the cable through the ceiling. Use the delivered screws and dowels to mount the bracket onto the ceiling.`}</p>
    <h3 {...{
      "id": "step-2-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2-1",
        "aria-label": "step 2 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/fcda8/9420-Step-5-DE.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBUlEQVQoz82STU/DMAyG9/9/1w4IJESZENIOHIKG1jQOdexkceqkqNtlYiC+LjzKKcrjV469mv/A6luval1Oa7+Sf5B8kTBtuunxQcFpzl8na63EPCLiOGbV6fqq3N3qMNR3MhH1fe+cs9aWlOZSNKXRWiHadB0AxBhlmk4VHYAbBmvtdLxZee+NMS+73X6/z4hzjAmA+14Dme02hBBjfAVoqk21iAiziNRaFxkAno4Q81xKSyl5n7wvGG7Wa2PMfdc9G8PMiEhEFBZEZJGZeTgSY5xV51KEGQfXRDKRqqaUQgjtgk8/jGNExJQzESGiqn48qvNK54jI4XDIOZ/a+8OG/Tv5DQ05NSwX410tAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4620ccdf3d0bd81ed2014cdc334922e/e4706/9420-Step-5-DE.avif 230w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/d1af7/9420-Step-5-DE.avif 460w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/9984b/9420-Step-5-DE.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d4620ccdf3d0bd81ed2014cdc334922e/a0b58/9420-Step-5-DE.webp 230w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/bc10c/9420-Step-5-DE.webp 460w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/5ca24/9420-Step-5-DE.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4620ccdf3d0bd81ed2014cdc334922e/81c8e/9420-Step-5-DE.png 230w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/08a84/9420-Step-5-DE.png 460w", "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/fcda8/9420-Step-5-DE.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d4620ccdf3d0bd81ed2014cdc334922e/fcda8/9420-Step-5-DE.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Connect the connection cable to the camera according to Fig.2.`}</p>
    <h3 {...{
      "id": "step-3-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3-1",
        "aria-label": "step 3 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3:`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c86711caac23e00b19286b338abeff5/fcda8/9420-Ceiling---Step-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOElEQVQoz42SzXLCIBSFff/36Av0Bbpw4VjduHARTTMNBCJDUkKFCJefdEaqYzvYeoYFXOabc+6F2ZST914IMQzDp5TeuemOZtedtfZ0OgGAc070/TAM7nwwFgAgxjil9QuO55IQglLKGGuahhFCm6ZljJcFr6uPvvvHOaUNIRBCCKUYoTeE1evL+/MTRqjBmBJCCVFK5eHEr1aruq6VUowx78ArmToyxlhrQgh3nY/HIwB472OM1tpxHKf7+gEbYwDgtqK19t5/zyU7sKvGcQwhxIumaYKzHnJWSiUmXhxSt7eVDJzutNa73U5K2XUdQqhtW855iv3QU202m8ViURTFfr/nnKdGHoJDCGVZzufz9XqNECKEaK2zgfPvLKVcLpfb7baqqsPh8Me0MnD6pxjjvu//CJzgLy5QL7agEIhaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c86711caac23e00b19286b338abeff5/e4706/9420-Ceiling---Step-3.avif 230w", "/en/static/8c86711caac23e00b19286b338abeff5/d1af7/9420-Ceiling---Step-3.avif 460w", "/en/static/8c86711caac23e00b19286b338abeff5/9984b/9420-Ceiling---Step-3.avif 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c86711caac23e00b19286b338abeff5/a0b58/9420-Ceiling---Step-3.webp 230w", "/en/static/8c86711caac23e00b19286b338abeff5/bc10c/9420-Ceiling---Step-3.webp 460w", "/en/static/8c86711caac23e00b19286b338abeff5/5ca24/9420-Ceiling---Step-3.webp 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c86711caac23e00b19286b338abeff5/81c8e/9420-Ceiling---Step-3.png 230w", "/en/static/8c86711caac23e00b19286b338abeff5/08a84/9420-Ceiling---Step-3.png 460w", "/en/static/8c86711caac23e00b19286b338abeff5/fcda8/9420-Ceiling---Step-3.png 590w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c86711caac23e00b19286b338abeff5/fcda8/9420-Ceiling---Step-3.png",
              "alt": "IN-9420 2k+ WQHD Schnellinstallation",
              "title": "IN-9420 2k+ WQHD Schnellinstallation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Loosen the screws on the neck of the bracket, put the camera in position and fix it on to the bracket by tightening the screws again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      